/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body{
    padding: 40px;
    height: 100vh;
    width: 100vw;
    background-image: repeating-linear-gradient(45deg, #d2e1ff36, transparent 463px);
  
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/img/euro-1353420.jpg') no-repeat center center fixed; 
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

/* video.background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.05;
} */

body {
  position: relative;
}

label{
    font-weight: bold;
}
table {
    border-collapse: collapse;
    width: 100%;
  }

  table{
    border: 1px solid lightskyblue;
    margin-bottom: 10px;
    cursor: pointer;
  }

  th, td {
    padding: 10px;
    text-align: left;
  }

  @media (max-width:500px) {
    body{
      padding: 15px
    }
  }

  @media (max-width:350px) {
    body{
      padding: 8px
    }
  }

  .form-control{
    border: 1px solid cornflowerblue ;
  }

  .form-check-input{
    border: 1px solid cornflowerblue !important;
  }